import PropTypes from 'prop-types';
import ColumnsWrapper from 'libs/storyblok/content/Modules/ColumnsWrapper';
import { columnsHeadingFontKeys } from 'config/branding/columns-header-font-keys';
import ColumnsLayout from '@activebrands/core-web/components/content-layouts/ColumnsLayout';
import media from '@activebrands/core-web/config/media';
import CmsDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getContentWrapperStyles from '@activebrands/core-web/utils/style-functions/get-content-wrapper-styles';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const TwoColumnsModule = ({ content = {}, isGrid = false }) => {
    const [css] = useStyletron();
    const {
        button = {},
        columns = [],
        heading = [],
        headingStyle = 'md',
        switchDesktopOrder = false,
        largeContentDesktopPosition = 'none',
    } = content;
    const headingSettings = transformHeading({ type: heading?.[0]?.type, defaultType: 'h3' });
    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};
    const contentWrapperStyles = getContentWrapperStyles();
    const hasLargeContent = largeContentDesktopPosition !== 'none';

    //The aspect ratio of the smaller image will set the height for the larger image, since the larger image will have a weird ratio of 115 / 84.
    const largeContentStyle = {
        none: {
            0: { aspectRatio: ['var(--ratio-vertical-primary)', null, null, null, null, 'var(--ratio-square)'] },
            1: { aspectRatio: ['var(--ratio-vertical-primary)', null, null, null, null, 'var(--ratio-square)'] },
        },
        left: {
            0: { gridColumn: '1 / span 8' },
            1: { gridColumn: '9 / span 4', aspectRatio: 'var(--ratio-vertical-tertiary)' },
        },
        right: {
            0: { gridColumn: '1 / span 4', aspectRatio: 'var(--ratio-vertical-tertiary)' },
            1: { gridColumn: '5 / span 8' },
        },
    };

    const largeContentColumns = columns;

    if (switchDesktopOrder && hasLargeContent) {
        largeContentColumns.reverse();
    }

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)', ...contentWrapperStyles })}>
            <div
                className={css({
                    alignItems: ['flex-start', null, 'center'],
                    display: 'flex',
                    gap: '16px',
                    justifyContent: 'space-between',
                    marginBottom: '24px',
                    flexDirection: ['column', null, 'row'],
                })}
            >
                {heading.length > 0 && (
                    <Heading fontKeys={columnsHeadingFontKeys[headingStyle]} type={headingSettings.type}>
                        {heading[0]?.text}
                    </Heading>
                )}
                {transformedButton.label && transformedButton.url && (
                    <ThemeButton
                        $style={{ display: ['none', null, null, 'block'] }}
                        as={Link}
                        rel={transformedButton.rel}
                        theme={transformedButton.theme}
                        to={transformedButton.url}
                    >
                        {transformedButton.label}
                    </ThemeButton>
                )}
            </div>

            <div
                className={css({
                    display: ['none', null, null, null, null, hasLargeContent ? 'grid' : 'none'],
                    gridTemplateColumns: 'repeat(12, 1fr)',
                    columnGap: '24px',
                })}
            >
                {largeContentColumns.map((column, i) => {
                    const isTextModule = column.component === 'textModule';

                    const textModuleStyles = {
                        height: '100%',
                        [media.max['mobile.sm']]: {
                            aspectRatio: 'auto',
                        },
                    };

                    const textModuleStyle = isTextModule ? textModuleStyles : {};

                    return (
                        <div
                            key={i}
                            className={css({
                                width: '100%',
                                height: '100%',
                                ...largeContentStyle[largeContentDesktopPosition]?.[i],
                                ...textModuleStyle,
                            })}
                        >
                            <CmsDataTransformerBlock isTwoColumns block={column} />
                        </div>
                    );
                })}
            </div>
            <ColumnsLayout
                $style={{
                    justifyContent: 'center',
                    display: ['flex', null, null, null, null, hasLargeContent ? 'none' : 'flex'],
                }}
                columnSizes={[12, null, 6]}
                gutterHeight={12}
                gutterWidth={['12', null, null, '16', null, '24']}
                items={columns.map((column, i) => {
                    const firstColumn = i === 0;
                    const isTextModule = column.component === 'textModule';
                    const textModuleStyles = { aspectRatio: 'auto', height: '100%' };
                    const textModuleStyle = isTextModule ? textModuleStyles : {};

                    return (
                        <ColumnsWrapper
                            $style={{
                                display: 'flex',
                                alignItems: 'center',
                                ...largeContentStyle[largeContentDesktopPosition]?.[i],
                                ...textModuleStyle,
                            }}
                            columnStyle={{
                                order: firstColumn && switchDesktopOrder ? [1, null, null, null, 2] : 1,
                            }}
                            component={column.component}
                            key={column._uid}
                            numberOfItems={columns.length}
                            position={i}
                            switchDesktopOrder={switchDesktopOrder}
                        >
                            <CmsDataTransformerBlock isTwoColumns block={column} />
                        </ColumnsWrapper>
                    );
                })}
            />
            {transformedButton.label && transformedButton.url && (
                <ThemeButton
                    $style={{
                        display: ['block', null, null, 'none'],
                        padding: 'var(--margin-content)',
                        marginTop: '24px',
                    }}
                    as={Link}
                    rel={transformedButton.rel}
                    theme={transformedButton.theme}
                    to={transformedButton.url}
                >
                    {transformedButton.label}
                </ThemeButton>
            )}
        </section>
    );
};

TwoColumnsModule.propTypes = {
    content: PropTypes.object,
    isGrid: PropTypes.bool,
};

export default TwoColumnsModule;
